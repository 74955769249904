import axios from 'axios';
import qs from 'qs';
import { TopVocabulary } from '../../types/samples';
import { newAbortSignal } from '../newAbortSignal';

export const getVocabulary = async (token: string, isWord: boolean) => {
  return await axios
    .get<TopVocabulary[]>(
      `${process.env.REACT_APP_BACKEND_URL}/samples/vocabulary`,
      {
        params: {
          token,
          isWord
        },
        paramsSerializer: {
          indexes: null,
          serialize: (params) => {
            return qs.stringify(params);
          }
        },
        timeout: parseInt(`${process.env.REACT_APP_API_TIMEOUT || '6000'}`),
        signal:
          typeof AbortSignal.timeout !== 'undefined'
            ? AbortSignal.timeout(
              parseInt(`${process.env.REACT_APP_API_TIMEOUT || '6000'}`)
            )
            : newAbortSignal(
              parseInt(`${process.env.REACT_APP_API_TIMEOUT || '6000'}`)
            )
      }
    )
    .then((response) => {
      return response.data;
    });
};
