import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslationHook } from '../../hooks/useTranslationHook/useTranslationHook';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { setLoadingValue } from '../../store/Site';
import { useDispatch } from 'react-redux';
import {
  getTopVocabulary,
  getTopVocabularyUsage,
  getVocabulary,
} from '../../api/samples';
import { TopVocabulary, TopVocabularyUsage } from '../../types/samples';
import Highlighter from 'react-highlight-words';

const Samples: FC = (): ReactElement => {
  const { t } = useTranslationHook();
  const dispatch = useDispatch();
  const [topVocabulary, setTopVocabulary] = useState<TopVocabulary[]>([]);
  const [topVocabularyUsage, setTopVocabularyUsage] = useState<
    TopVocabularyUsage[]
  >([]);
  const [page, setPage] = useState(0);
  const [selectedTokenId, setSelectedTokenId] = useState<number | undefined>(
    undefined
  );
  const [selectedToken, setSelectedToken] = useState<string | undefined>(
    undefined
  );
  const [inputToken, setInputToken] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isWordSwitchValue, setIsWordSwitchValue] = useState(true);
  const isLoadingTopVocabulary = useRef(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - topVocabulary.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchTopVocabulary = () => {
    if (isLoadingTopVocabulary.current) return;
    isLoadingTopVocabulary.current = true;
    getTopVocabulary(100, isWordSwitchValue)
      .then((response) => {
        setTopVocabulary(response);
        isLoadingTopVocabulary.current = false;
        dispatch(setLoadingValue(false));
      })
      .catch((error) => {
        isLoadingTopVocabulary.current = false;
        dispatch(setLoadingValue(false));
      });
  };

  const fetchTopVocabularyUsage = () => {
    if (!selectedTokenId) return;
    getTopVocabularyUsage(selectedTokenId, 30, isWordSwitchValue)
      .then((response) => {
        setTopVocabularyUsage(response);
        dispatch(setLoadingValue(false));
      })
      .catch((error) => {
        dispatch(setLoadingValue(false));
      });
  };

  const updateStats = () => {
    dispatch(setLoadingValue(true));
    fetchTopVocabulary();
  };

  const handleInputTokenClick = () => {
    if (!inputToken || inputToken.length === 0) return;
    setSelectedToken(undefined);
    setSelectedTokenId(undefined);
    try {
      dispatch(setLoadingValue(true));
      getVocabulary(inputToken, isWordSwitchValue)
        .then((response) => {
          if (response.length !== 1) {
            dispatch(setLoadingValue(false));
            return;
          }
          setSelectedToken(response[0].token);
          setSelectedTokenId(response[0].id);
        })
        .catch((error) => {
          console.log(error);
          dispatch(setLoadingValue(false));
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!selectedToken) {
      setTopVocabularyUsage([]);
      return;
    } else {
      dispatch(setLoadingValue(true));
      fetchTopVocabularyUsage();
    }
  }, [selectedToken]);

  useEffect(() => {
    if (topVocabulary) dispatch(setLoadingValue(false));
  }, [topVocabulary]);

  useEffect(() => {
    updateStats();
  }, [isWordSwitchValue])

  useEffect(() => {
    updateStats();
  }, []);

  return (
    <Container sx={{ marginTop: 5 }}>
      <Grid container alignItems='stretch' spacing={3}>
        <Grid className='left-pane' item md={6} xs={12}>
          <Container sx={{ marginBottom: 2 }}>
            <Box
              sx={{ '& > :not(style)': { m: 1 } }}
              component='form'
              noValidate
              autoComplete='off'
            >
              <FormControl variant='outlined'>
                <InputLabel
                  htmlFor='input-sample-text'
                  shrink
                  sx={{ marginTop: -2 }}
                >
                  You can write here a token to search
                </InputLabel>
                <TextField
                  id='input-sample-text'
                  size='small'
                  value={inputToken}
                  onChange={(e) => setInputToken(e.target.value.toLowerCase())}
                />
              </FormControl>
              <Button
                variant='contained'
                onClick={() => handleInputTokenClick()}
              >
                <SearchIcon />
              </Button>
              <FormControl variant='outlined'>
                <InputLabel
                  htmlFor='is-Word-Switch'
                  shrink
                  sx={{ marginTop: -2 }}
                >
                  Is Word?
                </InputLabel>
                <Switch
                  id='is-Word-Switch'
                  checked={isWordSwitchValue}
                  onChange={(e) => {
                    setIsWordSwitchValue(e.target.checked);
                  }
                  }
                />
              </FormControl>
            </Box>
          </Container>
          <Container>
            {topVocabulary.length > 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }} aria-label='Top Vocabulary'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Token</TableCell>
                      <TableCell align='center'>Appearances</TableCell>
                      <TableCell align='center'>Is Word</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? topVocabulary.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : topVocabulary
                    ).map((token, key) => {
                      return (
                        <TableRow
                          key={key}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                          onClick={() => {
                            if (
                              selectedTokenId &&
                              selectedTokenId === token.id
                            ) {
                              setSelectedTokenId(undefined);
                              setSelectedToken(undefined);
                            } else {
                              setSelectedTokenId(token.id);
                              setSelectedToken(token.token);
                              setInputToken(token.token);
                            }
                          }}
                          selected={
                            selectedToken ? selectedTokenId === token.id : false
                          }
                        >
                          <TableCell component='th' scope='row'>
                            {token.token}
                          </TableCell>
                          <TableCell align='center'>
                            {Intl.NumberFormat().format(token.appearance)}
                          </TableCell>
                          <TableCell align='center'>
                            {token.isword ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          10,
                          20,
                          50,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={3}
                        count={topVocabulary.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </Container>
        </Grid>
        <Grid className='right-pane' item md={6} xs={12}>
          {topVocabularyUsage.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label='Vocabulary Usage'>
                <TableHead>
                  <TableRow>
                    <TableCell>Text</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topVocabularyUsage.map((item, key) => {
                    return (
                      <TableRow
                        key={key}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <Highlighter
                            searchWords={selectedToken ? [selectedToken] : []}
                            autoEscape={true}
                            textToHighlight={item.phrase}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default Samples;
